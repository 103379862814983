<template>
  <fragment>
    <v-list-item-avatar class="mr-4">
      <AvatarImage
        :avatar="avatar"
        :avatar-key="item.picture"
        :color="item.id || 'blue'"
        :size="40" />
    </v-list-item-avatar>
    <UserInfo
      :user-info="item">
      <template
        v-slot="{
          userEmail,
          userProjectTeamRole: teamRole,
          userNameTitleAccordingToRole: userNameTitle
        }">
        <v-list-item-content class="mb-0">
          <v-list-item-title>
            {{ userNameTitle }}
            <span
              v-if="teamRole"
              class="subtitle-2">
              {{ teamRole }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ userEmail }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </UserInfo>
  </fragment>
</template>
<script>
export default {
  name: 'AppShareAutocompleteUserListItem',
  components: {
    UserInfo: () => import('@/components/App/AppShareModal/AppShareModalUserInfo'),
  },
  props: {
    item: {
      type: Object,
      default: () => ({
      }),
    },
    avatar: {
      type: String,
      default: null,
    },
  },
};
</script>
<style scoped lang="scss">
</style>
